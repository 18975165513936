<template>
  <styled-modal
    id="modal-form-password"
    :title="titleModal"
    @on-hidden="reset()"
    :hide-close="forcePassword"
  >
    <div class="pt-0">
      <form>
        <div class="mb-3">
          <typo type="body_web" class="mb-1" color="var(--primary-black)">
            {{ $t("LB_OLD_PASSWORD") }}
            <span style="color: red">*</span>
          </typo>
          <div class="form-group m-0">
            <b-form-input
              v-model="form_data.current_password"
              id="current_password"
              :type="!is_show_current_password ? 'password' : 'text'"
              :placeholder="$t('F_OLD_PASSWORD')"
              required
            >
            </b-form-input>
            <eye-icon
              :opened="is_show_current_password"
              @on-click="is_show_current_password = !is_show_current_password"
            />
          </div>
          <typo
            type="small_web"
            v-if="!is_validate && v$.form_data.current_password.$error"
            class="error-text"
            >{{ $t("VA_PLEASE_ENTER_OLD_PASSWORD") }}</typo
          >
        </div>

        <div class="mb-3">
          <typo type="body_web" class="mb-1">
            {{ $t("LB_NEW_PASSWORD") }}
            <span style="color: red">*</span>
          </typo>
          <div class="form-group m-0">
            <b-form-input
              v-model="form_data.new_password"
              id=""
              :type="!is_show_new_password ? 'password' : 'text'"
              @input="checkPassword"
              :placeholder="$t('F_NEW_PASSWORD')"
              required
            >
            </b-form-input>
            <eye-icon
              :opened="is_show_new_password"
              @on-click="is_show_new_password = !is_show_new_password"
            />
          </div>
          <typo
            type="small_web"
            class="error-text"
            v-if="!is_validate && !v$.form_data.new_password.required.$response"
          >
            {{ $t("VA_PLEASE_ENTER_NEW_PASSWORD") }}
          </typo>
          <typo
            type="small_web"
            class="error-text"
            v-else-if="!is_validate && !valid_password"
          >
            {{ $t("VA_PLEASE_ENTER_CORRECT_PASS_FORM") }}
          </typo>
          <typo type="small_web" class="error-text" v-else-if="contains_no_space">
            {{ $t("TXT_CONTAINS_NO_SPACE") }}
          </typo>
        </div>

        <div class="mb-3">
          <typo type="body_web" class="mb-1">
            {{ $t("TXT_CONFIRM_PASSWORD") }}
            <span style="color: red">*</span>
          </typo>
          <div class="form-group m-0">
            <b-form-input
              v-model="form_data.confirm_new_password"
              id=""
              :type="!is_show_confirm_password ? 'password' : 'text'"
              @input="checkPassword()"
              :placeholder="$t('F_CONFIRM_PASSWORD')"
              required
            >
            </b-form-input>
            <eye-icon
              :opened="is_show_confirm_password"
              @on-click="is_show_confirm_password = !is_show_confirm_password"
            />
          </div>
          <typo
            type="small_web"
            class="error-text"
            v-if="!is_validate && !v$.form_data.confirm_new_password.required.$response"
            >{{ $t("VA_PLEASE_ENTER_YOUR_CONFIRM_PASS") }}</typo
          >
          <typo
            type="small_web"
            class="error-text"
            v-else-if="
              !is_validate && !v$.form_data.confirm_new_password.sameAsPassword.$response
            "
            >{{ $t("VA_ENTER_CORRECT_PASSWORD_CONFIRMATION") }}</typo
          >
          <typo type="small_web" class="error-text" v-else-if="contains_no_space">
            {{ $t("TXT_CONTAINS_NO_SPACE") }}
          </typo>
        </div>

        <div class="d-flex gap-1 align my-4">
          <span
            :class="{ is_valid: contains_eight_characters }"
            class="d-flex align-items-center gap-2"
          >
            <success-orange-icon v-if="contains_eight_characters" />
            <img
              v-else
              src="@/assets/register/checkemp.svg"
              width="12px"
              height="13px"
              alt="check"
            />
            <typo>{{ $t("TXT_8_CHARACTERS") }}</typo>
          </span>
          <span
            :class="{ is_valid: contains_number }"
            class="d-flex align-items-center gap-2"
          >
            <success-orange-icon v-if="contains_number" />
            <img
              v-else
              src="@/assets/register/checkemp.svg"
              width="12px"
              height="13px"
              alt="check"
            />
            <typo>{{ $t("TXT_CONTAINS_NUMBER") }}</typo>
          </span>

          <span
            :class="{ is_valid: confirm_password }"
            class="d-flex align-items-center gap-2"
          >
            <success-orange-icon v-if="confirm_password" />

            <img
              v-else
              src="@/assets/register/checkemp.svg"
              width="12px"
              height="13px"
              alt="check"
            />
            <typo>{{ $t("TXT_CONFIRM_PASSWORD") }}</typo>
          </span>
        </div>
      </form>
    </div>
    <template v-slot:footer>
      <div class="d-flex ga justify-content-end mt-3">
        <styled-button
          type="primary"
          @on-click="openConfirmLogout"
          :disabled="!isValidate"
          style="width: 161px"
        >
          {{ $t("BTN_SAVE") }}
        </styled-button>
      </div>
    </template>

    <ModalWarning
      id="modal-update-password"
      :title="$t('TXT_HEADER_CONFIRM_PASSWORD_CHANGE')"
      :content="$t('TXT_SUB_CONFIRM_PASSWORD_CHANGE')"
      :submitFunction="onSave"
    />
  </styled-modal>
</template>
<script>
import { httpClient } from "@/services/axiosInstance.js";
import { apiURL } from "@/configs/serverConfig";
import { required, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import StyledModal from "../../Modal/StyledModal.vue";
import ModalWarning from "../../Modal/Alert/ModalWarning.vue";

export default {
  props: ["userType", "forcePassword"],
  components: {
    StyledModal,
    ModalWarning,
  },
  setup: () => ({ v$: useVuelidate() }),
  computed: {
    titleModal() {
      return this.$t("TXT_CHANGE_PASSWORD");
    },
  },
  data() {
    return {
      form_data: {
        current_password: null,
        new_password: null,
        confirm_new_password: null,
      },
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      // contains_uppercase: false,
      // contains_lowercase: false,
      // contains_special_character: false,
      contains_no_space: false,
      confirm_password: false,
      valid_password: false,
      is_validate: true, //chack validete
      isValidate: false, //chack disble btn save
      is_show_current_password: false,
      is_show_new_password: false,
      is_show_confirm_password: false,
    };
  },

  validations() {
    return {
      form_data: {
        current_password: { required },
        new_password: { required },
        confirm_new_password: {
          required,
          sameAsPassword: sameAs(this.form_data.new_password),
        },
      },
    };
  },
  watch: {
    contains_eight_characters: "checkIsValidate",
    contains_number: "checkIsValidate",
    // contains_uppercase: "checkIsValidate",
    // contains_lowercase: "checkIsValidate",
    // contains_special_character: "checkIsValidate",
    confirm_password: "checkIsValidate",
    valid_password: "checkIsValidate",
  },
  methods: {
    checkIsValidate() {
      this.isValidate =
        this.contains_eight_characters &&
        this.contains_number &&
        this.confirm_password &&
        this.valid_password;
    },
    reset() {
      this.form_data.current_password = null;
      this.form_data.new_password = null;
      this.form_data.confirm_new_password = null;
      this.password_length = 0;
      this.contains_eight_characters = false;
      this.contains_number = false;
      // this.contains_uppercase = false;
      // this.contains_lowercase = false;
      // this.contains_special_character = false;
      this.contains_no_space = false;
      this.confirm_password = false;
      this.valid_password = false;
      this.is_validate = true;
      this.isValidate = false;
      this.is_show_current_password = false;
      this.is_show_new_password = false;
      this.is_show_confirm_password = false;
    },
    async onSave() {
      if (this.isValidate) {
        const url = `${apiURL}/user/change_password`;
        const data = {
          current_password: this.form_data.current_password,
          new_password: this.form_data.new_password,
        };
        try {
          const res_password = await httpClient.post(url, data);
          if (res_password.status === 200) {
            // console.log("res_password", res_password);
            this.$toast.success({
              component: "styled-toast",
              props: {
                type: "success",
                message: this.$t("TXT_SAVE_DONE"),
              },
            });

            this.logout();
          }
        } catch (err) {
          this.$toast.error({
            component: "styled-toast",
            props: {
              type: "error",
              message: err.response.data.message,
            },
          });
        }
      } else {
        this.is_validate = false;
      }
    },
    async openConfirmLogout() {
      this.isValidate = await this.v$.$validate();
      if (this.isValidate) {
        this.$bvModal.show("modal-update-password");
      } else {
        this.is_validate = false;
      }
    },
    async logout() {
      this.$store.commit("setToken", "");
      this.$store.commit("setRoleAction", null);

      if (this.userType == 2 || this.userType == 3) {
        //auditor
        this.$router.push("/login-auditor");
      } else {
        this.$router.push("/");
      }
      // this.$router.go();
    },
    closeModal() {
      this.$bvModal.hide("modal-form-password");
    },
    checkPassword() {
      this.password_length = this.form_data.new_password.length;
      //eslint-disable-next-line
      // const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      const format_no_space = /\s/;
      if (this.password_length >= 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.form_data.new_password);
      this.contains_no_space =
        format_no_space.test(this.form_data.new_password) ||
        format_no_space.test(this.form_data.confirm_new_password)
          ? true
          : false;
      // console.log(this.form_data.new_password, this.contains_no_space);
      // this.contains_lowercase = /[a-z]/.test(this.form_data.new_password);
      // this.contains_uppercase = /[A-Z]/.test(this.form_data.new_password);
      // this.contains_special_character = format.test(
      //   this.form_data.new_password
      // );
      this.checkConfirmPassword();
      if (
        this.contains_eight_characters === true &&
        this.contains_number === true &&
        this.contains_no_space === false
        // &&
        // this.contains_special_character === true &&
        // this.contains_uppercase === true &&
        // this.contains_lowercase == true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    checkConfirmPassword() {
      if (this.form_data.new_password) {
        if (this.form_data.new_password === this.form_data.confirm_new_password) {
          this.confirm_password = true;
        } else {
          this.confirm_password = false;
        }
      } else {
        this.confirm_password = false;
      }
    },
  },
};
</script>
