<template>
  <styled-modal
    :title="title"
    :content="content"
    :id="id"
    icon
    :size="size || 'sm'"
    :hideBackdrop="id == 'modal-warning-double-login' ? true : false"
    hideHeader
    @on-hidden="$emit('closeFunction')"
  >
    <template v-slot:icon>
      <slot name="icon">
        <alert-icon />
      </slot>
    </template>
    <template v-slot:footer>
      <div class="d-flex gap-2 justify-content-end w-100">
        <div
          class="w-100"
          :class="{
            'd-flex gap-1 justify-content-between': isCancelBtn && isCustomBtnSubmit != true,
          }"
          v-if="submitFunction"
        >
          <styled-button
            v-if="isCancelBtn"
            :type="'secondary'"
            class="w-100"
            :class="{
              'mb-2' : isCustomBtnSubmit == true
            }"
            @on-click="closeModal"
            :block="!size"
          >
            {{ btnClosetName ? btnClosetName : $t("BTN_CANCEL") }}
          </styled-button>
          <styled-button  type="primary" class="w-100" @on-click="submitFunction(data)" :block="!size">
            {{ btnSubmitName ? btnSubmitName : $t("BTN_OK") }}
          </styled-button>
        </div>
        <div class="w-100" v-else>
          <styled-button
            :type="closeType ? closeType : 'secondary'"
            @on-click="closeModal"
            :block="!size"
          >
            {{ btnClosetName ? btnClosetName : $t("BTN_CLOSE") }}
          </styled-button>
        </div>
      </div>
    </template>
  </styled-modal>
</template>

<script>
import StyledModal from "@/components/Modal/StyledModal.vue";

export default {
  props: [
    "id",
    "size",
    "title",
    "content",
    "btnClosetName",
    "closeType",
    "isCancelBtn",
    "data",
    "submitFunction",
    "btnSubmitName",
    "isCustomBtnSubmit",
    "isCloseFunction"
  ],
  components: {
    StyledModal,
  },
  methods: {
    closeModal() {
      if(!this.isCloseFunction) {
        this.$bvModal.hide(`${this.id}`);
      } else {
        this.$emit("submitCloseFunction");
      }
    },
  },
  // watch: {
  //   dataSubmit: {
  //     handler: function (val) {
  //       this.data = val;
  //       console.log("watch val", val);
  //     },
  //     immediate: true,
  //   },
  // },
};
</script>
