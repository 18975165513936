import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import store from "@/store";
function checkToken() {
  if (store.state.token.length > 0) {
    return true;
  } else {
    return false;
  }
}

function checkPermission(permission) {
  if (permission) {
    if (permission.access == true) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

const routes = [
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/RegisterView.vue"),
  },
  // {
  //   path: "/organization",
  //   name: "organization",
  //   component: () => import("../views/organization/OrganizationalView.vue"),
  //   meta: {
  //     layout: "AppLayoutMain",
  //     key: "BC_ORGANIZATION_STRUCTURE",
  //     fixed_top_position_header: true,
  //   },
  //   beforeEnter: async (to, from, next) => {
  //     if (checkToken() == true) {
  //       if (
  //         checkPermission(
  //           store.state.permissions
  //             ? store.state.permissions.organization_structure
  //             : null
  //         ) == true
  //       ) {
  //         next();
  //       } else {
  //         next("/access-denied");
  //       }
  //     } else {
  //       next("/");
  //     }
  //   },
  // },
  // {
  //   path: "/branch",
  //   name: "โครงสร้างสาขา",
  //   component: () => import("../views/organization/BranchView.vue"),
  //   meta: {
  //     layout: "AppLayoutMain",
  //     key: "BC_BRANCH_STRUCTURE",
  //     fixed_top_position_header: true,
  //   },
  //   beforeEnter: async (to, from, next) => {
  //     if (checkToken() == true) {
  //       if (
  //         checkPermission(
  //           store.state.permissions
  //             ? store.state.permissions.organization_structure
  //             : null
  //         ) == true
  //       ) {
  //         next();
  //       } else {
  //         next("/access-denied");
  //       }
  //     } else {
  //       next("/");
  //     }
  //   },
  // },
  // {
  //   path: "/building",
  //   name: "building",
  //   component: () => import("../views/organization/BuildingView.vue"),
  //   meta: {
  //     layout: "AppLayoutMain",
  //     key: "TXT_BUILDING_STRUCTURE",
  //     fixed_top_position_header: true,
  //   },
  //   beforeEnter: async (to, from, next) => {
  //     if (checkToken() == true) {
  //       if (
  //         checkPermission(
  //           store.state.permissions
  //             ? store.state.permissions.organization_structure
  //             : null
  //         ) == true
  //       ) {
  //         next();
  //       } else {
  //         next("/access-denied");
  //       }
  //     } else {
  //       next("/");
  //     }
  //   },
  // },
  // {
  //   path: "/department",
  //   name: "department",
  //   component: () => import("../views/organization/DepartmentView.vue"),
  //   meta: {
  //     layout: "AppLayoutMain",
  //     key: "BC_DEPARTMENT_STRUCTURE",
  //     fixed_top_position_header: true,
  //   },
  //   beforeEnter: async (to, from, next) => {
  //     if (checkToken() == true) {
  //       if (
  //         checkPermission(
  //           store.state.permissions
  //             ? store.state.permissions.organization_structure
  //             : null
  //         ) == true
  //       ) {
  //         next();
  //       } else {
  //         next("/access-denied");
  //       }
  //     } else {
  //       next("/");
  //     }
  //   },
  // },
  // {
  //   path: "/setting-fiscalyear",
  //   name: "fiscalyear",
  //   component: () => import("../views/organization/FiscalYearView.vue"),
  //   meta: {
  //     layout: "AppLayoutMain",
  //     key: "TXT_FISCAL_YEAR",
  //     fixed_top_position_header: true,
  //   },
  //   beforeEnter: async (to, from, next) => {
  //     if (checkToken() == true) {
  //       if (
  //         checkPermission(
  //           store.state.permissions
  //             ? store.state.permissions.organization_structure
  //             : null
  //         ) == true
  //       ) {
  //         next();
  //       } else {
  //         next("/access-denied");
  //       }
  //     } else {
  //       next("/");
  //     }
  //   },
  // },
  {
    path: "/organization-setting",
    name: "organization-setting",
    component: () =>
      import("../views/organization/OrganizationSettingView.vue"),
    meta: {
      layout: "AppLayoutMain",
      key: "BTN_SETTING_ORGANIZATION",
      fixed_top_position_header: true,
    },
    children: [
      {
        path: "organization",
        name: "organization",
        component: () => import("../views/organization/OrganizationalView.vue"),
        meta: {
          layout: "AppLayoutMain",
          key: "BC_ORGANIZATION_STRUCTURE",
          fixed_top_position_header: true,
        },
      },
      {
        path: "branch",
        name: "branch",
        component: () => import("../views/organization/BranchView.vue"),
        meta: {
          layout: "AppLayoutMain",
          key: "BC_BRANCH_STRUCTURE",
          fixed_top_position_header: true,
        },
      },
      {
        path: "building",
        name: "building",
        component: () => import("../views/organization/BuildingView.vue"),
        meta: {
          layout: "AppLayoutMain",
          key: "TXT_BUILDING_STRUCTURE",
          fixed_top_position_header: true,
        },
      },
      {
        path: "setting-fiscalyear",
        name: "fiscalyear",
        component: () => import("../views/organization/FiscalYearView.vue"),
        meta: {
          layout: "AppLayoutMain",
          key: "TXT_FISCAL_YEAR",
          fixed_top_position_header: true,
        },
      },
    ],
    beforeEnter: async (to, from, next) => {
      if (checkToken() == true) {
        if (
          checkPermission(
            store.state.permissions
              ? store.state.permissions.organization_structure
              : null
          ) == true
        ) {
          next();
        } else {
          next("/access-denied");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/measurement-list",
    name: "measurement-list",
    component: () => import("../views/emission-log/MeasurementListView.vue"),
    meta: {
      layout: "AppLayoutMain",
      key: "BC_FILLIN_MEASURE",
      fixed_top_position_header: true,
    },
    children: [
      {
        path: "measurement-detail",
        name: "measurement-detail",
        component: () =>
          import("../views/emission-log/MeasurementDetailView.vue"),
        meta: {
          layout: "AppLayoutMain",
          key: "BC_FILLIN_TABLE",
          fixed_top_position_header: true,
        },
      },
    ],
    beforeEnter: async (to, from, next) => {
      if (checkToken() == true) {
        if (
          checkPermission(
            store.state.permissions ? store.state.permissions.filling_out : null
          ) == true
        ) {
          next();
        } else {
          next("/access-denied");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/reset",
    name: "Reset Password",
    component: () =>
      import(/* webpackChunkName: "reset" */ "../views/ResetView.vue"),
    props: (route) => ({
      email: route.query.email,
      code: route.query.code,
    }),
  },
  {
    path: "/approval",
    name: "approval",
    component: () =>
      import(
        /* webpackChunkName: "list-approve" */ "../views/emission-log/ApprovalListView.vue"
      ),
    meta: {
      layout: "AppLayoutMain",
      key: "BC_APPROVE_FILLIN",
      overflow_x_layout: true,
      fixed_top_position_header: true,  
    },
    beforeEnter: async (to, from, next) => {
      if (checkToken() == true) {
        if (
          checkPermission(
            store.state.permissions ? store.state.permissions.application : null
          ) == true
        ) {
          next();
        } else {
          next("/access-denied");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/manage-user-accounts",
    name: "manage-user-accounts",
    component: () => import("../views/user/ManageUsersAccountView.vue"),
    meta: {
      layout: "AppLayoutMain",
      key: "BC_USER",
      fixed_top_position_header: true,
    },
    children: [
      {
        path: "manage-users",
        name: "manage-users",
        component: () => import("../views/user/ManageUsersView.vue"),
        meta: {
          layout: "AppLayoutMain",
          key: "TXT_USER",
          fixed_top_position_header: true,
        },
      },
      {
        path: "manage-role-permission",
        name: "manage-role-permission",
        component: () => import("../views/user/ManageRolePermissionView.vue"),
        meta: {
          layout: "AppLayoutMain",
          key: "TXT_ROLE",
          fixed_top_position_header: true,
        },
      },
      {
        path: "assign-task",
        name: "assign-task",
        component: () => import("../views/user/AssignTaskView.vue"),
        meta: {
          layout: "AppLayoutMain",
          key: "TXT_ASSIGNMENT",
          fixed_top_position_header: true,
        },
      },
      {
        path: "manage-auditors",
        name: "manage-auditors",
        component: () => import("../views/user/ManageAuditorsView.vue"),
        meta: {
          layout: "AppLayoutMain",
          key: "BC_MANAGE_AUDIT",
          fixed_top_position_header: true,
        },
      },
    ],
    beforeEnter: async (to, from, next) => {
      if (checkToken() == true) {
        if (
          checkPermission(
            store.state.permissions
              ? store.state.permissions.user_account
              : null
          ) == true
        ) {
          next();
        } else {
          next("/access-denied");
        }
      } else {
        next("/");
      }
    },
  },
  // {
  //   path: "/member",
  //   name: "member",
  //   component: () => import("../views/user/ManageUsersView.vue"),
  //   meta: {
  //     layout: "AppLayoutMain",
  //     key: "BC_USER",
  //     fixed_top_position_header: true,
  //   },
  //   beforeEnter: async (to, from, next) => {
  //     if (checkToken() == true) {
  //       if (
  //         checkPermission(
  //           store.state.permissions
  //             ? store.state.permissions.user_account
  //             : null
  //         ) == true
  //       ) {
  //         next();
  //       } else {
  //         next("/access-denied");
  //       }
  //     } else {
  //       next("/");
  //     }
  //   },
  // },
  // {
  //   path: "/manage-role-permission",
  //   name: "manage-role-permission",
  //   component: () => import("../views/user/ManageRolePermissionView.vue"),
  //   meta: {
  //     layout: "AppLayoutMain",
  //     key: "BC_ASSIGN_ROLE",
  //     fixed_top_position_header: true,
  //   },
  //   beforeEnter: async (to, from, next) => {
  //     if (checkToken() == true) {
  //       if (
  //         checkPermission(
  //           store.state.permissions ? store.state.permissions.assign_role : null
  //         ) == true
  //       ) {
  //         next();
  //       } else {
  //         next("/access-denied");
  //       }
  //     } else {
  //       next("/");
  //     }
  //   },
  // },
  // {
  //   path: "/assign-task",
  //   name: "assign-task",
  //   component: () => import("../views/user/AssignTaskView.vue"),
  //   meta: {
  //     layout: "AppLayoutMain",
  //     key: "BC_ASSIGN_TASK",
  //     fixed_top_position_header: true,
  //   },
  //   beforeEnter: async (to, from, next) => {
  //     if (checkToken() == true) {
  //       if (
  //         checkPermission(
  //           store.state.permissions ? store.state.permissions.assign_task : null
  //         ) == true
  //       ) {
  //         next();
  //       } else {
  //         next("/access-denied");
  //       }
  //     } else {
  //       next("/");
  //     }
  //   },
  // },
  // {
  //   path: "/manage-auditors",
  //   name: "manage-auditors",
  //   component: () => import("../views/user/ManageAuditorsView.vue"),
  //   meta: {
  //     layout: "AppLayoutMain",
  //     key: "BC_MANAGE_AUDIT",
  //     fixed_top_position_header: true,
  //   },
  //   beforeEnter: async (to, from, next) => {
  //     if (checkToken() == true) {
  //       if (
  //         checkPermission(
  //           store.state.permissions
  //             ? store.state.permissions.user_account
  //             : null
  //         ) == true
  //       ) {
  //         next();
  //       } else {
  //         next("/access-denied");
  //       }
  //     } else {
  //       next("/");
  //     }
  //   },
  // },
  {
    path: "/manage-activities",
    name: "manage-activities",
    component: () => import("../views/activity/ManageActivitiesView.vue"),
    meta: {
      layout: "AppLayoutMain",
      key: "BC_ACTIVITY_INVENTORY",
      overflow_x_layout: false,
      fixed_top_position_header: true,
    },
    beforeEnter: async (to, from, next) => {
      if (checkToken() == true) {
        if (
          checkPermission(
            store.state.permissions
              ? store.state.permissions.measure_list
              : null
          ) == true
        ) {
          next();
        } else {
          next("/access-denied");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/callback",
    name: "callback-page",
    component: () => import("../views/callback/CallbackPage.vue"),
  },
  {
    path: "/insight-dashboard",
    name: "insight-dashboard",
    component: () => import("../views/dashboard/InsightDashboardView.vue"),
    meta: {
      layout: "AppLayoutMain",
      key: "BC_OVERVIEW",
      overflow_x_layout: false,
      fixed_top_position_header: true,
    },
    beforeEnter: async (to, from, next) => {
      if (checkToken() == true) {
        if (
          checkPermission(
            store.state.permissions ? store.state.permissions.overview : null
          ) == true
        ) {
          next();
        } else {
          next("/access-denied");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/access-denied",
    name: "Access Denied",
    component: () =>
      import(
        /* webpackChunkName: "access-denied" */ "../views/AccessDeniedPage.vue"
      ),
    meta: {
      layout: "AppLayoutMain",
    },
    beforeEnter: async (to, from, next) => {
      if (checkToken() == true) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/verify-information",
    name: "verify-information-list",
    component: () => import("../views/verify/VerifyInformationListView.vue"),
    meta: {
      layout: "AppLayoutMain",
      overflow_x_layout: true,
      fixed_top_position_header: true,
      key: "LB_VERIFY_INFORMATION",
    },
    children: [
      {
        path: "organization-information/:id/:mode",
        name: "organization-information",
        component: () =>
          import("../views/verify/OrganizationInformationView.vue"),
        meta: {
          layout: "AppLayoutMain",
          key: "BC_COPORATE_INFORMATION",
          fixed_top_position_header: true,
        },
      },
      {
        path: "insight-information/:id/:mode",
        name: "insight-information",
        component: () => import("../views/verify/InsightInformationView.vue"),
        meta: {
          layout: "AppLayoutMain",
          key: "TXT_INSIGHT_INFORMATION_ORGANIZATION",
          fixed_top_position_header: true,
        },
      },
      {
        path: "measure-list/:id/:mode",
        name: "measure-list",
        component: () =>
          import(
            /* webpackChunkName: "measure-list-report" */ "../views/verify/ActivityListView.vue"
          ),
        meta: {
          layout: "AppLayoutMain",
          key: "LB_ACTIVITY_LIST",
          fixed_top_position_header: true,
        },
      },
      {
        path: "/preview-file",
        name: "preview-file",
        component: () => import("../views/verify/PreviewFileActivity.vue"),
        meta: {
          layout: "AppLayoutMain",
          key: "BC_AUDIT_ACTIVITY",
          fixed_top_position_header: true,
        },
      },
      {
        path: "emission-factor/:id/:mode",
        name: "emission-factor",
        component: () => import("../views/verify/EmissionFactorView.vue"),
        meta: {
          layout: "AppLayoutMain",
          key: "BC_EMISSION_FACTOR",
          fixed_top_position_header: true,
        },
      },
      {
        path: "significant/:id/:mode",
        name: "significant",
        component: () => import("../views/verify/SignificantView.vue"),
        meta: {
          layout: "AppLayoutMain",
          key: "LB_SIGNIFICANT",
          fixed_top_position_header: true,
        },
      },
      {
        path: "measurement-uncertainty/:id/:mode",
        name: "measurement-uncertainty",
        component: () => import("../views/verify/UncertaintyView.vue"),
        meta: {
          layout: "AppLayoutMain",
          key: "TXT_UNCERTAINTY",
          fixed_top_position_header: true,
        },
      },
      {
        path: "correction-report/:id/:mode",
        name: "correction-report",
        component: () =>
          import(
            /* webpackChunkName: "correction-report" */ "../views/verify/CorrectionReportView.vue"
          ),
        meta: {
          layout: "AppLayoutMain",
          key: "TXT_DEBUG_REPORT",
          fixed_top_position_header: true,
        },
      },
    ],
    beforeEnter: async (to, from, next) => {
      if (checkToken() == true) {
        if (
          checkPermission(
            store.state.permissions ? store.state.permissions.audit : null
          ) == true
        ) {
          next();
        } else {
          next("/access-denied");
        }
      } else {
        next("/");
      }
    },
  },

  {
    path: "/login-auditor",
    name: "Login Auditor",
    component: () =>
      import(/* webpackChunkName: "login-auditor" */ "../views/LoginAudit.vue"),
  },
  {
    path: "/mapping-emission-factor",
    name: "mapping-emission-factor",
    component: () =>
      import("../views/emission-factor/MappingEmissionFactorView.vue"),
    meta: {
      layout: "AppLayoutMain",
      key: "LB_MAPPING_EMISSIONS_FACTOR",
      fixed_top_position_header: true,
    },
    beforeEnter: async (to, from, next) => {
      if (checkToken() == true) {
        if (
          checkPermission(
            store.state.permissions ? store.state.permissions.custom_ef : null
          ) == true
        ) {
          next();
        } else {
          next("/access-denied");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/custom-emission-factor",
    name: "custom-emission-factor",
    component: () =>
      import("../views/emission-factor/CustomEmissionFactorView.vue"),
    meta: {
      layout: "AppLayoutMain",
      key: "TXT_CUSTOM_EF",
      fixed_top_position_header: true,
    },
    children: [
      {
        path: "form",
        name: "custom-emission-factor-form",
        component: () =>
          import("../views/emission-factor/CustomEmissionFactorForm.vue"),
        meta: {
          layout: "AppLayoutMain",
          key: "TXT_FORM",
          fixed_top_position_header: true,
        },
      },
    ],
    beforeEnter: async (to, from, next) => {
      if (checkToken() == true) {
        if (
          checkPermission(
            store.state.permissions ? store.state.permissions.custom_ef : null
          ) == true
        ) {
          next();
        } else {
          next("/access-denied");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/overview-dashboard",
    name: "overview-dashboard",
    component: () => import("../views/dashboard/OverviewDashboardView.vue"),
    meta: {
      layout: "AppLayoutMain",
      key: "BC_OVERVIEW",
      overflow_x_layout: false,
      fixed_top_position_header: true,
    },
    beforeEnter: async (to, from, next) => {
      if (checkToken() == true) {
        if (
          checkPermission(
            store.state.permissions ? store.state.permissions.overview : null
          ) == true
        ) {
          next();
        } else {
          next("/access-denied");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/changelog",
    name: "changelog",
    component: () => import("../views/changelog/ChangelogView.vue"),
    meta: {
      layout: "AppLayoutMain",
      key: "BC_OVERVIEW",
      overflow_x_layout: false,
      fixed_top_position_header: true,
    },
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () =>
      import(
        /* webpackChunkName: "page-not-found" */ "../views/AccessDeniedPage.vue"
      ),
  },
];


function isMobile() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
}

const router = new VueRouter({
  mode: "history",
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name == "organization-setting") next({ name: 'organization' })

  if (to.name == "manage-user-accounts") next({ name: 'manage-users' })

  if (to.name == "NewLandingPage") {
    next();
  } else {
    if (isMobile()) {
      // mobile
      if (checkToken() == true) {
        if (
          to.name == "not-support" ||
          to.name == "Book Demo" ||
          to.name == "Thankyou"
        ) {
          next();
        } else {
          next("/browser-not-support");
        }
      } else {
        if (
          to.name == "Signin CPF" ||
          to.name == "Mobile Register Code" ||
          to.name == "Mobile Register" ||
          to.name == "Book Demo" ||
          to.name == "Thankyou"
        ) {
          next();
        } else {
          next("/");
        }
      }
    } else {
      // destop

      if (to.path == "/accept-invite") {
        if (to.name == "AcceptInvite") {
          next();
        } else {
          next("/accept-invite");
        }
      } else {
        if (store.state.auditor_type) {
          next();
        } else {
          if (from.path != "/") {
            if (checkToken() == true) {
              next();
            } else {
              if (
                to.name == "Login" ||
                to.name == "Register" ||
                to.name == "Form" ||
                to.name == "Book Demo" ||
                to.name == "Thankyou" ||
                to.name == "Login Auditor" ||
                to.name == "Reset Password"
              ) {
                next();
              } else {
                next("/");
              }
            }
          } else {
            if (checkToken() == true) {
              next();
              // if (checkIsSetupTemplate()) {
              //   next()
              // } else {
              //   if (to.name == 'Get-Started') {
              //     next()
              //   } else {
              //     next('/get-started')
              //   }
              // }
            } else {
              next();
            }
          }
        }
      }
    }
  }
});

export default router;
