<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.08471 16.2565L5.29164 12.214L4 13.5809L9.08471 19L20 7.36689L18.7175 6L9.08471 16.2565Z"
      :fill="color ? color : '#212121'"
    />
  </svg>
</template>

<script>
export default {
  props: ["color"],
};
</script>