<template>
  <div id="app">
    <!-- <navbar /> -->
    <div>
      <AppLayout>
        <!-- <button @click="refreshApp()" >testsadsads</button> -->
        <router-view />
      </AppLayout>
    </div>
    <!-- <footerPage /> -->
  </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
// import ModalStepsForUse from "@/components/Modal/ModalStepsForUse.vue";
export default {
  name: "App",
  components: {
    // navbar,
    // footerPage,
    // ModalStepsForUse,
    AppLayout,
  },
  data() {
    return {
      layout: `div`,
      refreshing: false,
      worker: null,
      updateExists: false,
    };
  },
  // methods: {
  //   handleNavigate() {
  //     this.visible = false;
  //   },
  // },
};
</script>

<style lang="scss">
#app {
  color: #2c3e50;
  background: #fbfcff;
}
</style>
